$spacer: 16px;
$primary: #08b0a0;
$secondary: #2e383d;
$danger: #e84d48;
$warning: #f5a623;
$info: #1aa3ff;
$success: #417505;
$black: #000000;
$white: #FFFFFF;
$gray-100: #f1f1f1;
$gray-300: #F5F5F5;
$gray-200: #D9DCE0;
$border-color: #e5e5e5;
$navy-gray:#707991;
$bg: rgba(0, 0, 0, 0.04);
$box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2),
0 4px 5px 0 rgba(0, 0, 0, 0.14),
0 1px 10px 0 rgba(0, 0, 0, 0.12);
$font-weight-400: 400;