$shiftToolHeight: 18px;
$shiftRadius: 3px;

.titleBox {
  font-size: 13px;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shift {
  box-shadow: 0 0 0 1px inset rgba(0, 0, 0, .05);
  border-radius: $shiftRadius;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  &.openShift {
    .shiftMain {
      background-color: rgba(167, 170, 169, .25) !important;
    }

    .shiftInfor {
      background-color: rgba(167, 170, 169, .5) !important;
    }
  }

  &.conflictShift {
    box-shadow: 0 0 0 2px inset red;
  }

  .shiftContentFull {
    border-radius: $shiftRadius;
    flex: 1 1 auto;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: $secondary;
  }

  &.emptyShift:hover {
    .shiftContentFull {
      display: flex;
    }

  }
}

.shiftWrapper {
  height: calc(100vh - #{48*2 + 16 + 37}px);
}

.shiftInfor {
  display: flex;
  justify-content: space-between;
  min-height: math.div($spacer,4);
  height: auto;
  margin-top: auto;
  border-bottom-left-radius: $shiftRadius;
  border-bottom-right-radius: $shiftRadius;

  [data-icon-name] {
    font-size: 11px !important;
    margin: 0 4px;
    line-height: $shiftToolHeight;
    display: block;
  }

  .shiftTypeIcon {
    font-size: 8px !important;
  }


  .shiftTypeText {
    font-weight: 600;
    font-size: 9px;
    line-height: 14px;
  }
}

.shiftMain {
  padding: 0 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
  border-top-left-radius: $shiftRadius;
  border-top-right-radius: $shiftRadius;
}

.shiftTime {
  font-weight: 500;
  margin-bottom: 1px;
}

.shiftName {
  font-size: 11px;
}

.shiftTime,
.shiftName {
  line-height: 1.3;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.boxShift {
  border-top: 1px solid $white;
  border-right: 1px solid $white;
  height: 100%;

  &.borderTop0 {
    border-top: 0;
  }
}

.colShift {
  &:last-child .boxShift {

    border-right: 0;
  }
}

.rowShiftBox,
.dataShiftBox {
  padding-left: $spacer;
  padding-right: $spacer;
}

.rowShiftBox {
  padding-top: $spacer;

  .boxShift {
    border-right: 0;
  }

  .colPrefix {
    border-top: 1px solid $gray-100;
    background-color: $gray-100;
  }
}

.dropShift {
  border-radius: $shiftRadius;

  &.isActive {
    .shift {
      box-shadow: 0 0 0 1px inset rgba(0, 0, 0, .25);
    }
  }
}

.verticalLine {
  border-right: 1px solid #d9d9d9;
  height: 20px;
}

.dateBox {
  padding: .25rem .5rem 0;
}

.dateTitle {
  font-size: 16px;
  font-weight: 500;
  color: rgba($black, .5);
  line-height: 1;
  margin-bottom: 2px;
}

.dateSubtitle {
  font-size: 10px;
  color: rgba($black, .5);
}

.dateToday {
  color: $primary;
}

.weekPreview {
  padding: .5rem 0;
  cursor: pointer;
}

.iconCustom {
  font-size: 10px !important;
}

.planningLoading {
  cursor: progress;
  pointer-events: none;
  position: relative;
}

.timePicker {
  max-height: 350px;
  width: 350px;
  background-color: $white;
  z-index: 10000;
  box-shadow: $box-shadow;
}