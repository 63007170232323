@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/segoeuil.ttf');
}

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/segoeui.ttf');
}

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/seguisb.ttf');
}

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/segoeuib.ttf');
}

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/segoeuib.ttf');
}

* {
  box-sizing: border-box;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100vh;
  min-width: 1200px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiDataGrid-main div[style='overflow: visible; height: 0px; width: 0px;'] {
  height: auto !important;
  width: unset !important;
}

.modalBody {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}

.MuiButton-textSecondary {
  color: #000 !important;
}

.buttonBadge {
  cursor: text !important;
  box-shadow: none !important;
}

.positionRelative {
  position: relative;
}

.modalLoadingOverlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
}

.required::after {
  content: '*';
  color: red;
}

.tableContainer {
  width: 100%;
  height: 555px;
}

.popper-color,
.popper-select {
  z-index: 10000;
}

.react-colorful__last-control,
.react-colorful__saturation {
  border-radius: 2px !important;
}

.react-colorful__alpha,
.react-colorful__hue {
  height: 18px !important;
}

.react-colorful__pointer {
  width: 20px !important;
  height: 20px !important;
}

.MuiListSubheader-root {
  text-transform: uppercase;
}

.MuiListSubheader-root {
  line-height: 36px !important;
}

.viewport {
  overflow-y: auto;
}

.pac-container {
  z-index: 9999 !important;
}

.mapInput {
  width: 100%;
  height: 36px;
  border: none;
  border-bottom: 1px solid $border-color;
  outline: none;
  padding: 0.5rem 0;
  font: inherit;
}

.mapInput:focus {
  border-color: $primary;
}

.circle {
  border-radius: 50%;
  border: 2px solid $primary;
  min-width: 200px;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../images/location-pin.png) rgba($primary, 0.4) no-repeat center center;
  background-size: 32px auto;
}

.row {
  width: 100%;
  min-height: 44px;
  border-bottom: 1px solid #e5e5e5;
}

.row-content:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.row-content {
  display: flex;
  align-items: center;
  min-height: 44px;
  width: 100%;
}

.row-detail {
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid #e5e5e5;
}

.row-detail .col1 {
  flex: 1;
  padding: 10px 10px 10px 55px;
}

.MuiDataGrid-root{
  border-color: transparent !important;
  .MuiDataGrid-withBorderColor{
    border-color: $border-color !important;
  }
}