.TimeSheet {
    height: 100vh;
    width: 100%;

    &-nav {
        padding: 12px 16px;
        background-color: rgba($primary, 0.15);
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 22px;
    }
}

.PeriodDatePicker {
    display: flex;
    align-items: center;
    gap: 12px;

    &-control {
        .MuiInputBase-root {
            min-width: 150px;
            background-color: $white;
        }
    }

    .MuiInputBase-root {
        max-width: 150px;
        background-color: $white;
    }
}

.Tools {
    display: flex;
    gap: 12px;
    align-items: center;

    &-icon {
        display: flex;
    }

    &-break {
        display: flex;
        align-items: center;
        padding: 8px 0;
        justify-content: space-between;
    }

    &-color {
        display: flex;
        gap: 20px;
        align-items: center;
        padding: 4px 0;
    }

    &-add {
        background-color: $primary;
        color: $white;
        height: 20px;
        width: 20px;
    }

    &-remove {
        background-color: $danger;
        color: $white;
        height: 20px;
        width: 20px;
    }

    &-width {
        min-width: 200px;
        li{
            flex-direction: row-reverse;
            justify-content: space-between;
            white-space: pre-wrap;
        }
    }
    &-text{
        width: 80%;
    }
}

.border {
    border-bottom: 1px solid $gray-100;
}

.icons-24 {
    font-size: 24px !important;
}

.icons-16 {
    font-size: 16px !important;

    &:hover {
        cursor: pointer;
    }
}

.WarningBox {
    min-width: 300px;
    max-height: 500px;

    &-item {
        &:hover {
            background-color: $gray-100;
        }
    }
}

.ShiftAbbreviation {
    padding: 0px 8px;
    border-radius: 10px;
    color: #fff;
    margin-left: 5px;
}

.TimeSheetAbbreviation {
    padding: 0px 8px;
    border-radius: 10px;
    color: #fff;
}

.NewTimesheet {

    &-hours {
        max-height: 300px;
    }

    .MuiOutlinedInput-root {
        padding: 0 9px !important;
    }

    .MuiOutlinedInput-root .MuiAutocomplete-input {
        padding: 0 !important;
    }

    .MuiAutocomplete-popper {
        width: 400px !important;
    }

    ul.MuiAutocomplete-listbox {
        display: flex;
        flex-wrap: wrap;
        padding: 8px !important;

        li {
            width: 25%;
            justify-content: center;
        }
    }



}

.ScheduledShift{
    display: flex;
    max-width: inherit;
    min-width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Departments {
    height: 50%;
    &-bold {
        span{
            font-weight: 500;
        }
    }
}
.link{
    color: #2f6092;
}
.link-cursor{
    color: #2f6092;
    cursor: pointer;
}