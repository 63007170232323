.chatItem {
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  flex: 1;
  align-items: center;
  cursor: pointer;
  padding: 12px 10px;

  &:hover {
    background-color: $gray-100;
  }

  &.active {
    background-color: $gray-200;

    &:hover {
      background-color: none;
    }
  }

  &-Containerinfo {
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    width: calc(100% - 48px - 16px);
  }

  &-info {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: $black;
    max-width: calc(100% - 50px);
    flex: 1 1 calc(100% - 50px);
  }

  &-avatarMessage {
    position: absolute !important;
    right: 100%;
    width: 48px !important;
    height: 48px !important;
  }

  &-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-message {
    color: $navy-gray;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-time {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1 1 50px;
    max-width: 50px;
  }
}

.search {
  text-align: center;
  padding: 8px 16px;
  height: 52px;

  &-input {
    width: 300px;
    height: 40px;
    border-radius: 22px;
    background-color: $gray-300;
    margin-bottom: 30px;

    .MuiOutlinedInput-root {
      font-size: 16px;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
}

//InPutMessenger
.InputMessenger {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  align-items: flex-end;
  gap: 8px;
  padding: 8px 12px 16px 12px;
  background-color: $white;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;

  &-replyClose {
    position: absolute !important;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  &-replyImage {
    width: 200px;
    height: 140px;
    object-fit: cover;
    padding: 10px;
  }

  &-iconClose {
    font-size: 15px;
  }

  &-send {
    margin-right: 10px;
  }

  &-input {
    flex: 1;
    background-color: $gray-100;
    border-radius: 12px;
    border: none;
    font-size: 16px;
    width: 100%;
    padding: 10px 50px;
    resize: none !important;
    &:focus-visible{
      outline: none;
    }
  }

  &-emoji {
    position: absolute;
    left: 0%;
    top: -436px;
  }
  &-box{
    position: relative;
    padding: 8px 0;
  }
  &-start{
    position: absolute;
    left: 12px;
    bottom: 14px;
  }
  &-end{
    position: absolute;
    right: 12px;
    bottom: 14px;
  }

  &-icon {
    cursor: pointer;
    font-size: 20px;
  }


  &-file {
    border-radius: 12px !important;
    margin-bottom: 10px;
    color: inherit; 
    &:hover{
      color: inherit;
    }
  }

  &-replyText {
    padding: 0 15px 0 0;
    white-space: nowrap;
    max-width: 800px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-reply {
    display: flex;
    gap: 10px;
    position: relative;
    max-height: 150px;
    padding: 10px;
    max-width: 1000px;
    word-break: break-all;
    min-width: 100px;
    border-bottom: 1px solid $gray-200;
  }

  &-containerInput {
    flex: 1;
    background-color: $gray-100;
    border-radius: 12px;
  }

}

//MessengerBox
.MessengerBox {
  width: 100%;
  flex: 1 1 100%;
  max-width: 1024px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  padding: 0 12px;

  &-item {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-name {
    font-size: 12px;
    color: $navy-gray;
  }

  &-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    a {
      color: $white;
    }
  }

  &-quantityHeart {
    position: absolute;
    bottom: -12px;
    display: flex;
    gap: 3px;
    background: $white;
    padding: 1px 5px;
    border-radius: 20px;
    left: 10px;
    font-size: 15px;
  }

  &-quantity {
    font-size: 13px;
    color: $black;
  }

  &-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding-left: 48px;

    .MuiAvatar-root {
      position: absolute;
      bottom: 25px;
      left: 0;
    }

    a {
      color: $black;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    padding: 4px 12px;
    border-radius: 12px;
    max-width: 70%;
    min-width: 100px;
    word-wrap: break-word;
    position: relative;

    img {
      width: 300px;
      height: 200px;
      object-fit: cover;
      max-width: 100%;
      margin: 8px 0;
      cursor: pointer;
    }
  }

  &-timestamp {
    display: flex;
    justify-content: center;
    margin: 5px 0;
  }

  &-currentDate {
    padding: 5px 10px;
    border-radius: 10px;
    background-color: $gray-100;
  }

  &--contentLeft {
    color: $black;
    background-color: $gray-100;
    word-wrap: break-word;
  }

  &--contentRight {
    color: $white;
    background: linear-gradient(to right, #29d4a9, #08b0a0);
    word-wrap: break-word;
  }

  &-timestampItem {
    padding: 8px;
    background-color: $gray-100;
    border-radius: 10px;
    color: $black;
    font-size: 14px;
  }

  &-iconRed {
    color: $navy-gray;
  }

  &-reactionHeart {
    color: $danger;
  }

  &-iconRed:hover {
    color: $danger;
  }

  &-reaction {
    display: none;
    gap: 5px;
    align-items: center;
  }


  &-reactionLeft {
    cursor: pointer;
    position: absolute;
    right: -40px;
    flex-wrap: wrap-reverse;
  }

  &-heartRight {
    order: 1;
  }

  &-reactionRight {
    cursor: pointer;
    position: absolute;
    left: -40px;
  }

  &-timestamp {
    display: flex;
    justify-content: center;
    margin: 8px;
  }
}


//UserMessenger
.UserMessenger {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  height: 56px;
  border-bottom: 1px solid $gray-200;

  &-overName{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    width: 400px;
    text-overflow: ellipsis;
  }
}

.Scroll-messengerBox {
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  flex-direction: column-reverse;
}

.infinite-scroll-component__outerdiv {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
}

.Scroll-chatItem {
  height: calc(100vh - 52px) !important;
}

.messenger {
  overflow: hidden;

  &-container {
    display: flex;
  }

  &-chatList {
    width: 364px;
    border-right: 2px solid $gray-300;
  }

  &-chatItem {
    height: calc(100vh - 52px);
  }

  &-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: calc(100vw - 364px);
  }

  &-search {
    display: flex;
    align-items: center;
  }
}

//GroupName
.GroupName {
  &-input {
    background-color: $gray-100;
    border-radius: 22px;
    height: 40px;
  }
}

//ContactsSelect
.ContactsSelect {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-box {
    height: 300px;
    overflow: auto;
  }

  &-item {
    display: flex;
    align-items: center;

    &:hover {
      background-color: $gray-100;
    }
  }

  &-search {
    background-color: $gray-100;
  }
}

.boder-none {
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}

ul {
  padding: 0 !important;
}

.welcomeBox {
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: $gray-100;
}

.MessengerBox-item:hover {
  .MessengerBox-reaction {
    display: flex;
  }
}